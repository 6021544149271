import React from 'react';

import styles from './project.module.scss';

import picture1 from '../../assets/images/test-pilots-1.jpg';
import picture2 from '../../assets/images/test-pilots-2.jpg';
import picture3 from '../../assets/images/test-pilots-3.jpg';

function TestPilots() {
  return (
    <main>
      <div className={styles.pageContainer}>
        <section className={styles.page}>
          <header className={styles.pageHeader}>
            <h1 className={styles.title}>TEST PILOTS</h1>
          </header>
          <div className={styles.pageContent}>
            <div className={styles.projectCanvas}>
              <div className={styles.projectModules}>
                <div className={styles.projectModule}>
                  <div className={styles.richText}>
                    <div>
                      User Testing has become a massive part of my role. Because of this, I developed the{' '}
                      <span>
                        <a
                          style={{ color: '#ea3d3d', textDecoration: 'underline' }}
                          target="_blank"
                          rel="noopener noreferrer"
                          href="https://www.linkedin.com/posts/byte-orbit_usertesting-productdesign-userexperiencedesign-activity-6580018180050300928-9QPn">
                          Byte Orbit Test Pilots
                        </a>
                      </span>
                      , a database of users from around the country who are excited and willing to be apart of our user
                      testing when we need them.
                    </div>
                    <div>
                      <br />
                      The database consists of a variety of ages, occupations and backgrounds, and is now more than 1100
                      users. We also developed a process of recruiting, screening, assembling and incentivising users to
                      allow for rapid user testing with quality results for both qualitative and quantitative user
                      research.
                    </div>
                    <div>
                      <br />
                      This database has been called on for several projects and has helped us test both internal and
                      client projects and is soon to expand.
                    </div>
                  </div>
                </div>
                <div className={styles.grid}>
                  <div className={styles.gridItem}>
                    <img src={picture1} />
                  </div>
                  <div className={styles.gridItem}>
                    <img src={picture2} />
                  </div>
                  <div className={styles.gridItem}>
                    <img src={picture3} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </main>
  );
}

export default TestPilots;
