import React from 'react';

import styles from './project.module.scss';

import picture1 from '../../assets/images/drone-1.jpg';
import picture2 from '../../assets/images/drone-2.jpg';
import picture3 from '../../assets/images/drone-3.jpg';
import picture4 from '../../assets/images/drone-4.jpg';
import picture5 from '../../assets/images/drone-5.jpg';

function Drone() {
  return (
    <main>
      <div className={styles.pageContainer}>
        <section className={styles.page}>
          <header className={styles.pageHeader}>
            <h1 className={styles.title}>DRONE USER PERSONAS</h1>
          </header>
          <div className={styles.pageContent}>
            <div className={styles.projectCanvas}>
              <div className={styles.projectModules}>
                <div className={styles.projectModule}>
                  <div className={styles.richText}>
                    <div>
                      As part of any product or marketing design, designers must understand their audience and customer
                      base. By understanding who is purchasing your product, what their needs and wants are and what
                      they’re using the product for, you can tailor your brand strategy to the information they are
                      looking for.
                    </div>
                    <div>
                      <br />
                      This brief asked us to research and report on four user personas of four products sold by a drone
                      manufacturer. The four reports needed to be in-depth and accurate to properly develop marketing
                      strategies for the four products.
                    </div>
                    <div>
                      <br />
                      The report breaks down the persona based on their usage and need for the drones. Some personas
                      were hobbyist drone flyers, while others were more professional drone users, using the product as
                      a tool for their profession.
                    </div>
                  </div>
                </div>
                <div className={styles.image}>
                  <img src={picture1} />
                </div>
                <div className={styles.grid}>
                  <div className={styles.gridItem}>
                    <img src={picture2} />
                  </div>
                  <div className={styles.gridItem}>
                    <img src={picture3} />
                  </div>
                  <div className={styles.gridItem}>
                    <img src={picture4} />
                  </div>
                  <div className={styles.gridItem}>
                    <img src={picture5} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </main>
  );
}

export default Drone;
