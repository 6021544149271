import React from 'react';

import styles from './project.module.scss';

import picture1 from '../../assets/images/pizza-1.jpg';
import picture2 from '../../assets/images/pizza-2.jpg';
import picture3 from '../../assets/images/pizza-3.jpg';
import picture4 from '../../assets/images/pizza-4.jpg';
import picture5 from '../../assets/images/pizza-5.jpg';

function Pizza() {
  return (
    <main>
      <div className={styles.pageContainer}>
        <section className={styles.page}>
          <header className={styles.pageHeader}>
            <h1 className={styles.title}>PIZZA-ORDERING SYSTEM</h1>
          </header>
          <div className={styles.pageContent}>
            <div className={styles.projectCanvas}>
              <div className={styles.projectModules}>
                <div className={styles.projectModule}>
                  <div className={styles.richText}>
                    <div>
                      This project allowed me to really explore my prototyping skills and gave me the opportunity to
                      test my Minimal Viable Product with users. The feedback from users became an important resource
                      during the design process because user experience was a central theme during the project. The
                      brief asked designers to create a system to order food at a sit-down or take-away restaurant.
                    </div>
                    <div>
                      <br />
                      The system had to include aspects for both the staff and customers that would make the process
                      easier and more user-friendly. After many interviews and observational research, I decided to use
                      New York Bagels in Harrington Street, Cape Town as my case study and customer.
                    </div>
                    <div>
                      <br />
                      They had an existing call-out system which connected to the two kitchens, but the lunch-time line
                      was still one of their biggest deterrent for customers. With a fast turn-around time and a small
                      capacity for walk-in customers, they were a great case study for better user experience.
                    </div>
                  </div>
                </div>
                <div className={styles.image}>
                  <img alt="Pizza ordering system app" src={picture1} />
                  <img alt="Storyboarding" src={picture2} />
                </div>
                <div className={styles.grid}>
                  <div className={styles.gridItem}>
                    <img src={picture3} />
                  </div>
                  <div className={styles.gridItem}>
                    <img src={picture4} />
                  </div>
                  <div className={styles.gridItem}>
                    <img src={picture5} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </main>
  );
}

export default Pizza;
