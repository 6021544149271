import React from 'react';
import { Link } from 'react-router-dom';
import styles from './project.module.scss';

const Project = ({ image, date, title, subtitle, link }) => {
	return (
		<Link to={link} className={styles.projectCover}>
			<div className={styles.coverContentContainer}>
				<div className={styles.coverImageWrap}>
					<div className={styles.coverImage}>
						<div className={styles.cover}>
							<img alt="Project" className={styles.image} src={image} />
						</div>
					</div>
				</div>
				<div className={styles.detailsWrap}>
					<div className={styles.details}>
						<div className={styles.detailsInner}>
							<div className={styles.date}>{date}</div>
							<div className={styles.title}>{title}</div>
							<div className={styles.subtitle}>{subtitle}</div>
						</div>
					</div>
				</div>
			</div>
		</Link>
	);
};

export default Project;
