import React from 'react';

import styles from './project.module.scss';

function HumanResources() {
  return (
    <main>
      <div className={styles.pageContainer}>
        <section className={styles.page}>
          <header className={styles.pageHeader}>
            <h1 className={styles.title}>HUMAN RESOURCES PLATFORM</h1>
          </header>
          <div className={styles.pageContent}>
            <div className={styles.projectCanvas}>
              <div className={styles.projectModules}>
                <div className={styles.projectModule}>
                  <div className={styles.richText}>
                    <div style={{ textAlign: 'center' }}>COMING SOON...</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </main>
  );
}

export default HumanResources;
