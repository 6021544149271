import React from 'react';

import styles from './project.module.scss';

import picture1 from '../../assets/images/project-fun-1.jpg';
import picture2 from '../../assets/images/project-fun-2.png';
import picture3 from '../../assets/images/project-fun-3.jpg';
import picture4 from '../../assets/images/project-fun-4.jpg';

function ProjectForFun() {
  return (
    <main>
      <div className={styles.pageContainer}>
        <section className={styles.page}>
          <header className={styles.pageHeader}>
            <h1 className={styles.title}>PROJECT FOR FUN</h1>
          </header>
          <div className={styles.pageContent}>
            <div className={styles.projectCanvas}>
              <div className={styles.projectModules}>
                <div className={styles.projectModule}>
                  <div className={styles.richText}>
                    <div>
                      “Human-centered design is a creative approach to problem solving... It’s a process that starts
                      with the people you’re designing for and ends with new solutions that are tailor made to suit
                      their needs. Human-centered design is all about building a deep empathy with the people you’re
                      designing for.”
                    </div>
                    <div>
                      <br />
                      This project tested our ability to empathise with our client and deliver a product that was
                      specialised to their needs. Our task was to create an infographic designed for children to teach
                      them about a topic that they would find interesting. We had to consider elements like a bright
                      colour pallet, limited copy and an easy to follow flow.
                    </div>
                    <div>
                      <br />
                      This poster is designed for children between the ages of nine to twelve and focuses on the topic
                      of mucus and its role within the body. The strategy was to use a gross and taboo topic to inform
                      children about their complicated immune system and the positive roll that something as gross as
                      snot can play.
                    </div>
                  </div>
                </div>
                <div className={styles.image}>
                  <img src={picture1} />
                </div>
                <div className={styles.grid}>
                  <div className={styles.gridItem}>
                    <img src={picture2} />
                  </div>
                  <div className={styles.gridItem}>
                    <img src={picture3} />
                  </div>
                  <div className={styles.gridItem}>
                    <img src={picture4} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </main>
  );
}

export default ProjectForFun;
