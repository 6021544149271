import React from 'react';

import styles from './project.module.scss';

import picture1 from '../../assets/images/me.jpg';

function AboutMe() {
  return (
    <main>
      <div className={styles.pageContainer}>
        <section className={styles.page}>
          <header className={styles.pageHeader}>
            <h1 className={styles.title}>ABOUT ME / CONTACT</h1>
          </header>
          <div className={styles.pageContent}>
            <div className={styles.projectCanvas}>
              <div className={styles.projectModules}>
                <div className={styles.image}>
                  <img alt="A picture of myself" src={picture1} />
                </div>
                <div className={styles.projectModule}>
                  <div className={styles.richText}>
                    <div>
                      I am passionate about user-centred design and enjoy conducting qualitative and quantitative
                      research with users, developing prototypes and using iterative design techniques to move towards a
                      final product. I am a keen follower of methodologies, such as ‘Lean Startup’, ‘Design Thinking’
                      and ‘Human-centred Design’ developed by Ideo. Part of this also includes running workshops which I
                      am passionate about.
                    </div>
                    <div>
                      I have experience working with agile Product Owners and Devs to produce and monitor digital
                      applications and websites both for clients and internal projects.
                    </div>
                    <div>
                      From a design perspective; I am proficient in Adobe Photoshop, Illustrator, XD and InDesign. I
                      also have experience with Figma, Sketch, Gravit and Invision.
                    </div>
                    <div>
                      Soft Skills:
                      <br />
                      Working on these projects has helped me to develop essential organisational skills, a strong work
                      ethic and a hard-working attitude. It has also helped me to mature my creative design skills,
                      problem-solving abilities and refined my presentation and visual story-telling strengths.
                    </div>
                    <div>
                      Being an energetic, young, woman in an organisation has meant that I have had to be flexible to
                      work in different environments, with different teams and on multiple projects at once.
                    </div>
                    <div>
                      On a more personal level:
                      <br />I am a positive-thinking, chai-tea-loving foodie, who is invigorated by new ideas and loves
                      to see people around me flourish. I enjoy hobbies such as gardening, baking and yoga and can
                      promise a host of freshly-baked office treats.
                    </div>
                    <div>To continue this in person, please feel free to contact me:</div>
                    <div style={{ lineHeight: '11px', textAlign: 'left' }} className={styles.subtitle}>
                      Email: amyleigh.tayler@gmail.com
                    </div>
                    <div style={{ lineHeight: '11px', textAlign: 'left' }} className={styles.subtitle}>
                      Cellphone: 0715685036
                    </div>
                    <div style={{ lineHeight: '11px', textAlign: 'left' }} className={styles.subtitle}>
                      LinkedIn:{' '}
                      <a
                        style={{ color: 'black', textDecoration: 'underline' }}
                        href="https://www.linkedin.com/in/amytayler/"
                        target="_blank"
                        rel="noopener noreferrer">
                        https://www.linkedin.com/in/amytayler/
                      </a>
                    </div>
                    <div style={{ lineHeight: '11px', textAlign: 'left' }} className={styles.subtitle}>
                      Resume:{' '}
                      <a
                        style={{ color: 'black', textDecoration: 'underline' }}
                        href="https://drive.google.com/file/d/1xrCwdkW_4ngUojRTB2Ssjrmz9N9XkJ_0/view?usp=sharing"
                        target="_blank"
                        download
                        rel="noopener noreferrer">
                        Click Here to View
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </main>
  );
}

export default AboutMe;
