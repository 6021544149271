import React from 'react';
import { Route } from 'react-router';
import { BrowserRouter } from 'react-router-dom';

import styles from './app.module.scss';
import Sidebar from './components/layout/sidebar';

import { Home, Shyft, TestPilots, Dine4Six, Drone, Pizza, ProjectForFun, HumanResources, Contact } from './screens';

function App() {
  return (
    <>
      <div className={styles.siteContainer}>
        <BrowserRouter>
          <Sidebar />
          <Route exact path="/" component={Home} />
          <Route exact path="/shyft-foreign-exchange-app" component={Shyft} />
          <Route exact path="/test-pilots" component={TestPilots} />
          <Route exact path="/dine4six" component={Dine4Six} />
          <Route exact path="/drone-user-personas" component={Drone} />
          <Route exact path="/pizza-ordering-system" component={Pizza} />
          <Route exact path="/project-for-fun" component={ProjectForFun} />
          <Route exact path="/human-resources-platform" component={HumanResources} />
          <Route exact path="/about-me-contact" component={Contact} />
          <footer></footer>
        </BrowserRouter>
      </div>
    </>
  );
}

export default App;
