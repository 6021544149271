import React, { useState } from 'react';
import { Link, withRouter } from 'react-router-dom';

import styles from './sidebar.module.scss';

const Sidebar = (props) => {
  const path = props.location.pathname;

  const [showBurger, setShowBurger] = useState(false);

  if (showBurger) {
    return (
      <div className={styles.jsResponsiveNav}>
        <div className={styles.responsiveNav}>
          <div className={styles.closeResponsiveClickArea} onClick={() => setShowBurger(false)}>
            <div className={styles.closeResponsiveButton}></div>
          </div>
          <nav className={styles.jsEditableTarget}>
            <ul className={styles.group}>
              <li className={styles.galleryTitle}>
                <Link onClick={() => setShowBurger(false)} to="/">
                  WORK
                </Link>
              </li>
              <li className={styles.projectTitle}>
                <Link
                  onClick={() => setShowBurger(false)}
                  to="/shyft-foreign-exchange-app"
                  className={path === '/shyft-foreign-exchange-app' ? styles.active : undefined}>
                  Shyft
                </Link>
              </li>
              <li className={styles.projectTitle}>
                <Link
                  onClick={() => setShowBurger(false)}
                  to="/test-pilots"
                  className={path === '/test-pilots' ? styles.active : undefined}>
                  Test Pilots
                </Link>
              </li>
              <li className={styles.projectTitle}>
                <Link
                  onClick={() => setShowBurger(false)}
                  to="/dine4six"
                  className={path === '/dine4six' ? styles.active : undefined}>
                  Dine4Six
                </Link>
              </li>
              <li className={styles.projectTitle}>
                <Link
                  onClick={() => setShowBurger(false)}
                  to="/drone-user-personas"
                  className={path === '/drone-user-personas' ? styles.active : undefined}>
                  Drone User Personas
                </Link>
              </li>
              <li className={styles.projectTitle}>
                <Link
                  onClick={() => setShowBurger(false)}
                  to="/project-for-fun"
                  className={path === '/project-for-fun' ? styles.active : undefined}>
                  Project For Fun
                </Link>
              </li>
              <li className={styles.projectTitle}>
                <Link
                  onClick={() => setShowBurger(false)}
                  to="/about-me-contact"
                  className={path === '/about-me-contact' ? styles.active : undefined}>
                  About Me / Contact
                </Link>
              </li>
            </ul>
            <div className={styles.social}>
              <ul>
                <li>
                  <a href="https://www.linkedin.com/in/amytayler/" target="_blank">
                    <svg
                      version="1.1"
                      id="Layer_1"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                      viewBox="0 0 30 24"
                      xmlSpace="preserve">
                      <path
                        id="path-1_24_"
                        d="M19.6,19v-5.8c0-1.4-0.5-2.4-1.7-2.4c-1,0-1.5,0.7-1.8,1.3C16,12.3,16,12.6,16,13v6h-3.4
            c0,0,0.1-9.8,0-10.8H16v1.5c0,0,0,0,0,0h0v0C16.4,9,17.2,7.9,19,7.9c2.3,0,4,1.5,4,4.9V19H19.6z M8.9,6.7L8.9,6.7
            C7.7,6.7,7,5.9,7,4.9C7,3.8,7.8,3,8.9,3s1.9,0.8,1.9,1.9C10.9,5.9,10.1,6.7,8.9,6.7z M10.6,19H7.2V8.2h3.4V19z"></path>
                    </svg>
                  </a>
                </li>
                <li>
                  <a href="mailto:amyleigh.tayler@gmail.com">
                    <svg
                      version="1.1"
                      id="Layer_1"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                      x="0px"
                      y="0px"
                      viewBox="0 0 30 24"
                      xmlSpace="preserve">
                      <g>
                        <path d="M15,13L7.1,7.1c0-0.5,0.4-1,1-1h13.8c0.5,0,1,0.4,1,1L15,13z M15,14.8l7.9-5.9v8.1c0,0.5-0.4,1-1,1H8.1c-0.5,0-1-0.4-1-1 V8.8L15,14.8z"></path>
                      </g>
                    </svg>
                  </a>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.sidebarContainer}>
      <header className={styles.headerContainer}>
        <div className={styles.header}>
          <Link to="/">AMY TAYLER</Link>
        </div>

        <div className={styles.hamburgerClickArea} onClick={() => setShowBurger(true)}>
          <div className={styles.hamburger}>
            <i></i>
            <i></i>
            <i></i>
          </div>
        </div>
      </header>

      <nav className={styles.workContainer}>
        <ul className={styles.group}>
          <li className={styles.galleryTitle}>
            <Link to="/">WORK</Link>
          </li>

          <li className={styles.projectTitle}>
            <Link
              to="/shyft-foreign-exchange-app"
              className={path === '/shyft-foreign-exchange-app' ? styles.active : undefined}>
              Shyft
            </Link>
          </li>
          <li className={styles.projectTitle}>
            <Link to="/test-pilots" className={path === '/test-pilots' ? styles.active : undefined}>
              Test Pilots
            </Link>
          </li>
          <li className={styles.projectTitle}>
            <Link to="/dine4six" className={path === '/dine4six' ? styles.active : undefined}>
              Dine4Six
            </Link>
          </li>
          <li className={styles.projectTitle}>
            <Link to="/drone-user-personas" className={path === '/drone-user-personas' ? styles.active : undefined}>
              Drone User Personas
            </Link>
          </li>

          <li className={styles.projectTitle}>
            <Link to="/project-for-fun" className={path === '/project-for-fun' ? styles.active : undefined}>
              Project For Fun
            </Link>
          </li>

          <li className={styles.projectTitle}>
            <Link to="/about-me-contact" className={path === '/about-me-contact' ? styles.active : undefined}>
              About Me / Contact
            </Link>
          </li>
        </ul>
        <div style={{ textAlign: 'center' }} className={styles.social}>
          <ul>
            <li>
              <a href="mailto:amyleigh.tayler@gmail.com">
                <svg
                  version="1.1"
                  id="Layer_1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  x="0px"
                  y="0px"
                  viewBox="0 0 30 24"
                  xmlSpace="preserve"
                  className={styles.icon}>
                  <g>
                    <path d="M15,13L7.1,7.1c0-0.5,0.4-1,1-1h13.8c0.5,0,1,0.4,1,1L15,13z M15,14.8l7.9-5.9v8.1c0,0.5-0.4,1-1,1H8.1c-0.5,0-1-0.4-1-1 V8.8L15,14.8z"></path>
                  </g>
                </svg>
              </a>
            </li>
            <li>
              <a href="https://www.linkedin.com/in/amytayler/" target="_blank">
                <svg
                  version="1.1"
                  id="Layer_1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  viewBox="0 0 30 24"
                  xmlSpace="preserve"
                  className={styles.icon}>
                  <path
                    id="path-1_24_"
                    d="M19.6,19v-5.8c0-1.4-0.5-2.4-1.7-2.4c-1,0-1.5,0.7-1.8,1.3C16,12.3,16,12.6,16,13v6h-3.4
            c0,0,0.1-9.8,0-10.8H16v1.5c0,0,0,0,0,0h0v0C16.4,9,17.2,7.9,19,7.9c2.3,0,4,1.5,4,4.9V19H19.6z M8.9,6.7L8.9,6.7
            C7.7,6.7,7,5.9,7,4.9C7,3.8,7.8,3,8.9,3s1.9,0.8,1.9,1.9C10.9,5.9,10.1,6.7,8.9,6.7z M10.6,19H7.2V8.2h3.4V19z"></path>
                </svg>
              </a>
            </li>
            <li>
              <a href="https://medium.com/@amytayler" target="_blank">
                <svg
                  version="1.1"
                  id="Layer_1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  viewBox="0 0 40 24"
                  xmlSpace="preserve"
                  className={styles.icon}>
                  <path d="M2.846 6.887c.03-.295-.083-.586-.303-.784l-2.24-2.7v-.403h6.958l5.378 11.795 4.728-11.795h6.633v.403l-1.916 1.837c-.165.126-.247.333-.213.538v13.498c-.034.204.048.411.213.537l1.871 1.837v.403h-9.412v-.403l1.939-1.882c.19-.19.19-.246.19-.537v-10.91l-5.389 13.688h-.728l-6.275-13.688v9.174c-.052.385.076.774.347 1.052l2.521 3.058v.404h-7.148v-.404l2.521-3.058c.27-.279.39-.67.325-1.052v-10.608z" />
                </svg>
              </a>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  );
};

export default withRouter(Sidebar);
