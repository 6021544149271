import React from 'react';

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import './react-tabs.scss';

import styles from './project.module.scss';

import shyft1 from '../../assets/images/shyft-1.jpg';
import shyft2 from '../../assets/images/shyft-2.jpg';
import shyft3 from '../../assets/images/shyft-3.jpg';
import shyft4 from '../../assets/images/shyft-4.jpg';
import shyft5 from '../../assets/images/shyft-5.jpg';
import shyft6 from '../../assets/images/shyft-6.jpg';
import shyft7 from '../../assets/images/shyft-7.jpg';
import shyft8 from '../../assets/images/shyft-8.jpg';

const Bold = ({ children }) => <span style={{ fontWeight: 'bold' }}>{children}</span>;

function Shyft() {
  return (
    <main>
      <div className={styles.pageContainer}>
        <section className={styles.page}>
          <header className={styles.pageHeader}>
            <h1 className={styles.title}>SHYFT</h1>
          </header>
          <div className={styles.pageContent}>
            <div className={styles.projectCanvas}>
              <div style={{ padding: '14px 5% 20px' }}>
                <img src={shyft2} style={{ width: '100%', height: 'auto' }}></img>
                <Tabs>
                  <TabList>
                    <Tab style={{ fontFamily: "'Open Sans', sans-serif" }}>The Process</Tab>
                    <Tab style={{ fontFamily: "'Open Sans', sans-serif" }}>The Designs</Tab>
                  </TabList>

                  <TabPanel>
                    <Process />
                  </TabPanel>
                  <TabPanel>
                    <Design />
                  </TabPanel>
                </Tabs>
              </div>
            </div>
          </div>
        </section>
      </div>
    </main>
  );
}

function Process() {
  return (
    <React.Fragment>
      {/* <img src={shyft2} style={{ width: '100%', height: 'auto' }}></img> */}
      <div className={styles.richText}>
        <div style={{ fontStyle: 'italic' }}>
          Standard Bank tasked us with creating a vision for their Foreign Exchange application;{' '}
          <span>
            <a
              rel="noopener noreferrer"
              style={{ color: 'black', textDecoration: 'underline' }}
              href="https://getshyft.co.za/"
              target="_blank">
              Shyft
            </a>
          </span>{' '}
          as well as updating the app for a more extensive user base. This brief included conducting user research,
          designing a new UI and UX, creating a road map for future features as well as developing them.
        </div>

        <div>
          Shyft has since received Special Honours in the{' '}
          <a
            rel="noopener noreferrer"
            style={{ color: 'black', textDecoration: 'underline' }}
            href="https://youtu.be/FBYD21fqJJQ?t=1006"
            target="_blank">
            Global Finance 2020 Innovators Awards.
          </a>
        </div>

        <div
          style={{
            display: 'grid',
            gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 1fr))',
            gridAutoRows: '1fr'
          }}>
          <div>
            <Bold>User Experience:</Bold>
            <ul style={{ padding: 0 }}>
              <li>Discovery Research</li>
              <li>Client Scoping</li>
              <li>Desktop Research</li>
              <li>Expert Stakeholder Interviews</li>
              <li>Workshop facilitation</li>
              <li>Ideation</li>
              <li>User Research Interviews</li>
              <li>Concept Validation Interviews</li>
              <li>Concept Acceptance Testing</li>
              <li>Usability Testing</li>
              <li>Feedback loop management</li>
            </ul>
          </div>

          <div>
            <Bold>User Interface:</Bold>
            <ul style={{ padding: 0 }}>
              <li>Wireframing</li>
              <li>App Design</li>
              <li>Interaction Design</li>
            </ul>
          </div>

          <div>
            <Bold>Content:</Bold>
            <ul style={{ padding: 0 }}>
              <li>UX Writing</li>
              <li>Marketing Content</li>
              <li>Writing</li>
            </ul>
          </div>

          <div>
            <Bold>Project Management: </Bold>
            <ul style={{ padding: 0 }}>
              <li>Stakeholder Management</li>
              <li>Collaboration with Support Team</li>
              <li>Collaboration with Delivery/ Development team</li>
            </ul>
          </div>
        </div>

        <img src={shyft3} style={{ width: '100%', height: 'auto' }}></img>

        <div>
          <Bold>Brief:</Bold>
          <br />
          Shyft was growing its user base and interest. Our client wanted to develop a concept for the next release of
          the app that could define an exciting roadmap for the product and give users a clear vision for what the value
          of the product was, and could be in the future.
          <br />
          <br />
          {/* */}
          <Bold>Research period:</Bold>
          <br />
          The Team started with what we knew, or could easily know. We spoke to the Shyft Customer Experience team about
          common problems customers were experiencing, requests for new features as well as their expert opinion as to
          where the product could go.
          <br />
          <br />
          {/* */}
          <Bold>Scoping:</Bold>
          <br />
          We conducted a 2-day-long <Bold>Scoping session</Bold> with the client to better understand their needs and
          expectations. It became clear that the client was ambitious and wanted us to look at international competitors
          not just in the Forex space but also at any new banking solution that was making waves.
          <br />
          <br />
          Our scoping session was followed by a <Bold>proposal</Bold>; our assessment of how the 12-week engagement
          could work, what deliverables we could deliver and what steps we thought were necessary to produce our best
          work.
          <br />
          <br />
          {/* */}
          <Bold>Competitor Research:</Bold>
          <br />
          Our next step was to conduct more <Bold>formal Desktop and User Feedback Sessions</Bold>. We looked into
          dozens of international products, ranging from Marcus to Acorn and RobinHood. By studying how these products
          developed, we could start thinking about Shyft’s roadmap as a product. We also started talking formally to
          Shyft Users, conducting interviews, setting up surveys to be sent to the existing database, friends of the
          product who wouldn’t mind sitting with us for hours at a time and people who were Shyft’s target market but
          had never used the product.
          <br />
          <br />
          {/* */}
          <Bold>Ideation:</Bold>
          <br />
          Once we presented these findings to the client, we could set up a series of <Bold>Ideation Workshops</Bold>.
          These sessions were structured around Shyft personas; archetypes which we had put together based on
          interviews, how they used Shyft, which features they used as well as data from the Shyft database (collected
          for us by Shyft’s data scientist at Standard Bank). We took two personas at a time and ideated blue sky
          concepts of where Shyft could be in the future for these users. These often fell into a template of:
          <br />
          <br />
          Persona x needs…..to….in order to….
          <br />
          <br />
          Day two of these ideation workshops with the entire client team (12 people in total) took these ideas and
          broke them down into features. We then asked our key decision-makers to prioritise these features by what they
          thought would bring the biggest value to this user and the business.
          <br />
          <br />
          {/* */}
          <img src={shyft4} style={{ width: '100%', height: 'auto' }}></img>
          <br />
          <br />
          Day three allowed us to think practically and look at the technical feasibility of each solution.
          <br />
          <br />
          {/* */}
          <Bold>User Testing:</Bold>
          <br />
          After conducting three workshops, we were ready to <Bold>test these ideas with users</Bold>. We booked
          interview time with 10 people who fit into each archetype. Armed with rough paper prototypes in a wire-bound
          book and a set of questions, we conducted over 50 interviews to conduct <Bold>Concept Validation</Bold>.
          <br />
          <br />
          After presenting the feedback from these interviews to the clients, they were able to make a decision on the
          direction of the product, which we could use to develop a proposed timeline for the next two releases of the
          product including design and development time.
          <br />
          <br />
          {/* */}
          <Bold>Design:</Bold>
          <br />
          We spent the next two months designing the app to future-proof the design for the features to come, as well as
          design the new features. We started with <Bold>Figma Wireframes</Bold> developed with the client which we
          presented to our UI Designers and Product Owners to better understand technical feasibility. Our UI Designers
          got started working on designs while we tested <Bold>Concept Acceptance</Bold> with existing Shyft users
          through one on one interviews.
          <br />
          <br />
          Once we had completed testing and our team had a clickable prototype, we presented back to the client and
          technical team. Sprint planning could now commence.
          <br />
          <br />
          During the <Bold>Development process</Bold>, our team stayed on standby and were called in if designs needed
          to be changed for technical reasons. We became User Experience consultants as decisions needed to be made.
          <br />
          <br />
          Even after the product has been launched, we work closely with the technical and support team to identify the
          most common customer experience issues and propose design and system changes to give the best user experience.
          <br />
          <br />
          {/* */}
          The new Shyft Update can be seen{' '}
          <span>
            <a
              rel="noopener noreferrer"
              style={{ color: 'black', textDecoration: 'underline' }}
              href="https://getshyft.co.za/"
              target="_blank">
              here
            </a>
          </span>
          .
        </div>
      </div>
    </React.Fragment>
  );
}

function Design() {
  return (
    <React.Fragment>
      {/* <img src={shyft2} style={{ width: '100%', height: 'auto' }}></img> */}
      <div className={styles.richText}>
        <div style={{ fontStyle: 'italic' }}>
          Standard Bank tasked us with creating a vision for their Foreign Exchange application;{' '}
          <span>
            <a
              rel="noopener noreferrer"
              style={{ color: 'black', textDecoration: 'underline' }}
              href="https://getshyft.co.za/"
              target="_blank">
              Shyft
            </a>
          </span>{' '}
          as well as updating the app for a more extensive user base. This brief included conducting user research,
          designing a new UI and UX, creating a road map for future features as well as developing them.
        </div>

        <div>
          Shyft has since received Special Honours in the{' '}
          <a
            rel="noopener noreferrer"
            style={{ color: 'black', textDecoration: 'underline' }}
            href="https://youtu.be/FBYD21fqJJQ?t=1006"
            target="_blank">
            Global Finance 2020 Innovators Awards.
          </a>
        </div>

        <div>
          <Bold>Date:</Bold>
          <br />
          2018 to Current
        </div>

        <div
          style={{
            display: 'grid',
            gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 1fr))',
            gridAutoRows: '1fr'
          }}>
          <div>
            <Bold>User Experience:</Bold>
            <ul style={{ padding: 0 }}>
              <li>Discovery Research</li>
              <li>Client Scoping</li>
              <li>Desktop Research</li>
              <li>Expert Stakeholder Interviews</li>
              <li>Workshop facilitation</li>
              <li>Ideation</li>
              <li>User Research Interviews</li>
              <li>Concept Validation Interviews</li>
              <li>Concept Acceptance Testing</li>
              <li>Usability Testing</li>
              <li>Feedback loop management</li>
            </ul>
          </div>

          <div>
            <Bold>User Interface:</Bold>
            <ul style={{ padding: 0 }}>
              <li>Wireframing</li>
              <li>App Design</li>
              <li>Interaction Design</li>
            </ul>
          </div>

          <div>
            <Bold>Content:</Bold>
            <ul style={{ padding: 0 }}>
              <li>UX Writing</li>
              <li>Marketing Content</li>
              <li>Writing</li>
            </ul>
          </div>

          <div>
            <Bold>Project Management: </Bold>
            <ul style={{ padding: 0 }}>
              <li>Stakeholder Management</li>
              <li>Collaboration with Support Team</li>
              <li>Collaboration with Delivery/ Development team</li>
            </ul>
          </div>
        </div>

        <img src={shyft5} style={{ width: '100%', height: 'auto' }}></img>
        <br />
        <br />

        <div>
          <Bold>Brief:</Bold>
          <br />
          Shyft was growing its user base and interest. Our client wanted to develop a concept for the next release of
          the app that could define an exciting roadmap for the product and give users a clear vision for what the value
          of the product was, and could be in the future.
          <br />
          <br />
          This included new features and a complete UI refresh which would future-proof the app for the vision of the
          product.
          {/* */}
          <br />
          <br />
          <div className={styles.imageTextContainer}>
            <img src={shyft6}></img>
            <div>
              <Bold>Globalview:</Bold>
              <br />
              One of the biggest feature changes on Shyft included a new dashboard view of all your wallets, cards and
              currencies.
              <br />
              <br />
              Shyft’s Globalview screen allows users to view where in the world their money is stored and gives them an
              overall view of their assets within Shyft.
              <br />
              <br />
              This screen includes a widget for a global map, wallet balances, card balances, a currency calculator and
              allows room for expansion as the app continues to add features and currencies.
            </div>
          </div>
          {/* */}
          <div className={styles.imageTextContainer}>
            <img src={shyft8}></img>
            <div>
              <Bold>Foreign Currency Exchange:</Bold>
              <br />
              In previous versions of Shyft, the main focus of the app was centred around Foreign Exchange. This would
              change with the new update, focusing instead on the users holistic transactional capabilities within the
              app.
              <br />
              <br />
              By moving the Forex screen into a tab rather than as the main screen, we were able to open up the space to
              create the Globalview screen. This also means that we were able to ensure that all of the real estate on
              the Forex tab was allocated to elements on Foreign Exchange.
            </div>
          </div>
          {/* */}
          <div className={styles.imageTextContainer}>
            <img src={shyft7}></img>
            <div>
              <Bold>Transact Tab:</Bold>
              <br />
              With more users using the Shyft cards than ever before, we had to consider a better transactional view
              that highlighted both the Forex wallets and the Shyft Mastercards.
              <br />
              <br />
              Our new Transact Tab gave major real-estate to a graph which breaks down Card vs Wallet balances and a
              Transaction History List.
              <br />
              <br />
              Tabs are broken down into currencies so that users can quickly toggle between currencies.
            </div>
          </div>
          {/* */}
          <div className={styles.imageTextContainer}>
            <img src={shyft2}></img>
            <div>
              <br />
              We also designed and wrote copy for the Shyft Website which informs users on the functionality and gives
              them access to Shyft Support.
            </div>
          </div>
          {/* */}
        </div>
      </div>
    </React.Fragment>
  );
}

export default Shyft;
