import React from 'react';

import Project from '../../components/project/project';
import styles from './home.module.scss';

import picture1 from '../../assets/images/home/shyft.jpg';
import picture2 from '../../assets/images/home/test-pilots.jpg';
import picture3 from '../../assets/images/home/dine4six.jpg';
import picture4 from '../../assets/images/home/drones.jpg';
import picture6 from '../../assets/images/home/snot.jpg';
import picture8 from '../../assets/images/home/me.jpg';

function Home() {
  return (
    <main>
      <section className={styles.projectCovers}>
        <Project
          title="shyft"
          date="2019"
          link="shyft-foreign-exchange-app"
          subtitle="User Testing, UX, UI"
          image={picture1}
        />
        <Project title="test pilots" date="2019" link="test-pilots" subtitle="User Testing" image={picture2} />
        <Project title="DINE4SIX" date="2019" link="dine4six" subtitle="Workshop Facilitation" image={picture3} />
        <Project
          title="drone user personas"
          date="2018"
          link="drone-user-personas"
          subtitle="Character Design, Graphic Design, Illustration, User Testing, Interviews"
          image={picture4}
        />
        <Project
          title="project for fun"
          date="2018"
          link="project-for-fun"
          subtitle="Illustration, Graphic design"
          image={picture6}
        />
        <Project title="About me / contact" date="" link="about-me-contact" subtitle="" image={picture8} />
      </section>
    </main>
  );
}

export default Home;
