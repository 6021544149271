import React from 'react';

import styles from './project.module.scss';

import picture1 from '../../assets/images/dine4six-1.jpg';
import picture2 from '../../assets/images/dine4six-2.jpg';

function Dix4Six() {
  return (
    <main>
      <div className={styles.pageContainer}>
        <section className={styles.page}>
          <header className={styles.pageHeader}>
            <h1 className={styles.title}>DINE4SIX</h1>
          </header>
          <div className={styles.pageContent}>
            <div className={styles.projectCanvas}>
              <div className={styles.projectModules}>
                <div className={styles.projectModule}>
                  <div className={styles.richText}>
                    <div>
                      <span>
                        <a
                          style={{ color: 'black', textDecoration: 'underline' }}
                          target="_blank"
                          rel="noopener noreferrer"
                          href="https://dine4six.com/global/">
                          Dine4Six
                        </a>
                      </span>{' '}
                      has become a pioneer in the dining industry, developing the social dining concept in South Africa
                      . This web platform and app encourages and facilitates 6 strangers to enjoy a fine-dining meal
                      together at one of the country’s top restaurants.
                    </div>
                    <div>
                      <br />A company full of passion wanted to explore business and revenue models for the platform as
                      it grows in the international market. I planned and facilitated a workshop to explore the Lean
                      Startup methodology with employees and facilitated ideation sessions on future vision.
                    </div>
                    <div>
                      <br />
                      The workshop yielded 3 validated possible business models for the company to explore, with their
                      employees excited and ready for lean and Rapid change.
                    </div>
                  </div>
                </div>
                <div className={styles.image}>
                  <img alt="Dine4Six Platform" src={picture1} />

                  <img alt="Dine4Six Platform" src={picture2} />
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </main>
  );
}

export default Dix4Six;
